:root {
  --white: white;
  --black: black;

  --purple: #5a24fb;
  /* --purple: #5a00ff; */

  /* --purple: #7f40fc; */
  --red: #ef5350;

  --green: #01d758;

  --gray50: #f7fafc;
  --gray100: #e3e8ee;
  --gray200: #c1c9d2;
  --gray300: #a3acb9;
  --gray400: #8792a2;
  --gray500: #697386;
  --gray600: #4f566b;
  --gray700: #3c4257;
  --gray800: #2a2f45;
  --gray900: #1a1f36;
}
