@import 'reset';
@import 'mui';

html {
  height: 100%;
}

body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', sans-serif;
  height: 100%;
  background-color: #fff;
  line-height: 1.5;
}

#root {
  min-height: 100%;
}

a {
  text-decoration: none;
}
