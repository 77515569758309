:root {
  --popover-shadow: rgba(0, 0, 0, 0.08) 0 3px 10px 0;
  --outline-grey: #d8d8d8;
  /* --purple: #5a24fb; */
  --purple: #5a00ff;
  --label-color: #2f384c;
}

.MuiPopover-paper {
  box-shadow: var(--popover-shadow);
  border: 1px solid var(--outline-grey);
  border-radius: 8px;
  margin-top: 6px;
}

.MuiPopover-paper .MuiMenu-list {
  padding: 8px;
}

.MuiPopover-paper .MuiMenuItem-root {
  border-radius: 5px;
}

.MuiPaper-root.MuiAutocomplete-paper {
  border-radius: 8px;
  padding: 8px;
  box-shadow: var(--popover-shadow);
  border: 1px solid var(--outline-grey);
  margin-top: 6px;
  margin-bottom: 6px;
  font-size: 14px;
}

.MuiAutocomplete-listbox {
  padding: 0;
}

.MuiAutocomplete-listbox .MuiAutocomplete-option {
  border-radius: 5px;
  padding: 8px;
}

.MuiInputBase-root,
.MuiAutocomplete-root {
  border-radius: 8px;
  width: 100%;
}

.MuiInputBase-root.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-width: 2px;
}

.MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0 0 0 6px;
}

.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding-bottom: 9px;
  padding-left: 0;
}

.MuiInputBase-root label.Mui-focused {
  color: black;
}

.MuiFormControl-root {
  display: block;
}

.MuiOutlinedInput-input {
  border-color: var(--purple);
}

.MuiOutlinedInput-root.Mui-focused:focus {
  border-color: var(--purple);
}

.MuiOutlinedInput-notchedOutline {
  border-color: var(--outline-grey);
}

body .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--purple);
}

.MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
  border-color: var(--outline-grey);
}

.MuiInputBase-input {
  font-size: 14px;
  height: 40px;
  box-sizing: border-box;
  border-radius: 8px;
}

.MuiInputBase-input:disabled {
  cursor: not-allowed;
}

.MuiInputLabel-root {
  font-size: 14px;
  transform: none;
  position: unset;
}

.MuiFormControlLabel-label {
  font-size: 14px;
  font-weight: 500;
}

.Mui-disabled .MuiRadio-root {
  color: #959595;
}

.MuiRadio-root svg {
  width: 20px;
  height: 20px;
}

.MuiFormLabel-root {
  color: var(--label-color);
  font-size: 14px;
  margin: 4px 0 8px;
}

.MuiFormLabel-root.MuiInputLabel-root.Mui-focused {
  color: var(--label-color);
}

.MuiButtonBase-root.MuiMenuItem-root.Mui-selected {
  background-color: rgb(89 36 251 / 8%);
}

.MuiFormControlLabel-root {
  width: 100%;
}

.MuiFormGroup-root {
  gap: 12px;
  flex-direction: column;
}

.MuiIconButton-root:hover {
  background: none;
}

.MuiToggleButtonGroup-root {
  border-radius: 8px;
  padding: 4px;
  border: 1px solid var(--outline-grey);
}

.MuiToggleButtonGroup-root button {
  font-size: 13px;
  text-transform: none;
}

.MuiToggleButtonGroup-root.text-options button {
  padding: 0 12px;
}

.MuiToggleButton-root {
  border-radius: 5px;
  padding: 0 8px;
  border: none;
  color: #bebebe;
}

.MuiToggleButton-root:hover {
  background: none;
  color: #707070;
}

.MuiToggleButton-root.Mui-selected {
  color: var(--purple);
  background-color: rgba(0, 0, 0, 0.05);
}

.MuiToggleButton-root.Mui-selected:hover {
  color: var(--purple);
  background-color: rgba(0, 0, 0, 0.05);
}

.MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped:not(:first-of-type) {
  border-radius: 5px;
}

.MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped:not(:last-of-type) {
  border-radius: 5px;
}

.MuiTableCell-root {
  padding: 16px 10px;
  white-space: nowrap;
}

/*
.MuiTableBody-root .MuiTableRow-root:hover .MuiTableCell-root,
.MuiTableBody-root .MuiTableRow-root.active .MuiTableCell-root {
  background-color: #f6f8fa;
}
*/

.MuiSwitch-root {
  height: 45px;
  width: 65px;
}

.MuiSwitch-switchBase.Mui-checked {
  color: white;
  transform: translateX(19px);
}

.MuiSwitch-thumb {
  width: 16px;
  height: 16px;
}

.MuiSwitch-switchBase + .MuiSwitch-track {
  opacity: 0.3;
  height: 22px;
  border-radius: 11px;
}

.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  opacity: 1;
  background-color: var(--purple);
}

.MuiSwitch-switchBase {
  padding: 15px;
}

.MuiTableRow-head .MuiTableCell-root {
  font-size: 12px;
  text-transform: uppercase;
  padding: 8px 10px;
}

.MuiTooltip-tooltip {
  background-color: rgb(65 68 81);
  border-radius: 8px;
  padding: 4px 10px;
  font-size: 12px;
}

.panelValueField {
  flex: 1;
}

.panelValueField input {
  font-size: 13px;
  height: 32px;
}

.overlayPanelValueField input,
.overlayPanelValueField .MuiSelect-select {
  font-size: 13px;
  height: 36px;
  line-height: 20px;
}

.panelValueField .MuiOutlinedInput-notchedOutline {
  border: none;
}

.panelValueField .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border: 1px solid var(--outline-grey);
}

.panelValueField .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid var(--purple);
}

.panelValueField .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border: 2px solid #d32f2f;
}

.MuiPaper-rounded {
  border-radius: 8px;
}

.MuiDialog-paper {
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.16);
}

.MuiAlert-standardError {
  border-radius: 8px;
}
